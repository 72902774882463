import React, { useEffect } from 'react';
import './App.css';
import Navba from './commponents/Nav/Nav';
import Home from './commponents/Home/Home';
import Footer from './commponents/footer/Footer';
import i18n from './i18n'; // التأكد من استيراد i18n
import 'animate.css/animate.min.css';

function App() {

  useEffect(() => {
    const defaultLang = localStorage.getItem('i18nextLng') || 'ar'; // استخدم اللغة المخزنة أو العربية كافتراضية
    i18n.changeLanguage(defaultLang);
  }, []);

  return (
    <div className="App">
      <Navba />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
