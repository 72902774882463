import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import '../Home/Home.css'
import { useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import WOW from 'wowjs';
import 'animate.css/animate.min.css';
import Swal from 'sweetalert2'; // استيراد SweetAlert2



const Home = () => {
  const {t} = useTranslation();
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    Swal.fire({
      title: "success",
      text: "The Message Has Been Sent",
      icon: "success"
    });
  };
    useEffect(() => {
      new WOW.WOW({
        live: false
      }).init();
    }, []);
  
  return (
    <>
    <section id="home" className='home' >
    <Carousel fade>
      <Carousel.Item>
        <img src='1 (5).png' alt=''/>
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='1 (4).png' alt=''/>
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='1 (3).jpeg' alt=''/>
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='1 (2).jpeg' alt=''/>
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='1 (5).png' alt=''/>
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    </section>
  
      <section id="about" className='about' >
        <div className='container'>
          <div className='row'>
          <div className='col-lg-7'>
          <h2>{t('Aboutheader')}</h2>
              <div className='about-text'>
                <ul>
                 <h3>{t('Abouthydra')}</h3>
                  <li>
                   <span className='caretright'><FontAwesomeIcon icon={faCaretRight} /></span>
                   <span className='caretleft'><FontAwesomeIcon icon={faCaretLeft} /> </span> 
                 <p>{t('Abouthydratext')}</p> 
                  </li>

                </ul>
              </div>
              <div className='about-text'>
                <ul>
                 <h3>{t('Aboutmall')}</h3>
                 <li>
                   <span className='caretright'><FontAwesomeIcon icon={faCaretRight} /></span>
                   <span className='caretleft'><FontAwesomeIcon icon={faCaretLeft} /> </span> 
                 <p>{t('Aboutmalltext')}</p> 
                  </li>
                  <li>
                   <span className='caretright'><FontAwesomeIcon icon={faCaretRight} /></span>
                   <span className='caretleft'><FontAwesomeIcon icon={faCaretLeft} /> </span> 
                 <p>{t('Aboutmalltext2')}</p> 
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-lg-5'>
              <div className='image-about'>
              <img src='1 (5).png' alt=''/>
              <img src='1 (4).png' alt=''/>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="contact" className='contact'>
        <div className='container'>
          <h2>{t("Contact Us")}</h2>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='contact-form'>
                <h4>{t('Send us a Message')}</h4>
                <form onSubmit={handleSubmit}>
        <input type='text' placeholder={t('Your Name')} className='form-control' required />
        <input type='text' placeholder={t('Phone Number')} className='form-control' required />
        <textarea rows="5" cols="100" placeholder={t('Send Message')} className="form-control" required></textarea>
        <button type='submit' className='btn'>{t('Send Message')}</button>
      </form>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='contact-address'>
                <h4>{t('Contact Details')}</h4>
                <ul>
                  <li>No. 70 - Al Mutamayez North of Madinah, in front of Badr City Metro Station, Badr City</li>
                  <li ><span>{t('Phone')}</span><b>:</b><a href='tel:01040507850' dir='ltr'> +20 104 050 7850</a></li>
                  <li ><span>{t('Phone')}</span><b>:</b><a href='tel:01011990590' dir='ltr'> +20 101 199 0590</a></li>
                  <li><span>{t('Email')}</span><b>:</b><a href='mailto:Info@Hydraeg.com'>Info@Hydraeg.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='map'>
        <h3>{t("map")}</h3>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.8573355154454!2d31.717773925362636!3d30.17315561246264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1457f9e28ca3048b%3A0xae71bcf45a75fb02!2z2YXZiNmEINin2YTZgdik2KfYryBFbGZvYWFkIE1hbGw!5e1!3m2!1sar!2seg!4v1728380335495!5m2!1sar!2seg"
       width="600"
        height="450" 
        style={{ border: 0 }}
        allowfullscreen 
        loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade">
      </iframe>
      </section>
    <div className='chaty-channels'>
      <div className='chaty-channel-list'>
        <div className='chaty-channel Phone-channel'>
          <a href='tel:0 104 050 7850' className='link-Phone wow animate__animated animate__bounceIn' data-hover="يسعدنا اتصالك" >
          <FontAwesomeIcon icon={faPhone} />
          </a>
        </div>
        <div className='chaty-channel Whatsapp-channel'>
          <a href='https://wa.me/message/GNDUK6AJLP2VC1' className='link-Whatsapp wow animate__animated animate__bounceInLeft' data-hover="يسعدنا تواصلك على الواتس آب">
          <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      </div>
    </div>
    </>
  )
}

export default Home